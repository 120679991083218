import { FC } from "react"
import { Field, FormikErrors, FormikTouched } from "formik"
import { ErrorMessage } from "./ErrorMessage"

// types
import { IdtFormValues } from "./IdtForm"

export const BillingAddress: FC<{
  errorClassName: (
    keyName: keyof IdtFormValues,
    errors: FormikErrors<IdtFormValues>,
    touched: FormikTouched<IdtFormValues>
  ) => "" | "is-invalid"
  errors: FormikErrors<IdtFormValues>
  touched: FormikTouched<IdtFormValues>
}> = ({ errorClassName, errors, touched }) => {
  return (
    <>
      <h2 className="gzl-Heading gzl-Heading--tertiary">Billing Address</h2>
      <div className={`gzl-FormField ${errorClassName("billing_address", errors, touched)}`}>
        <label className="gzl-FormField-label" htmlFor="billing_address">
          Street Address
        </label>
        <div className="gzl-FormField-control gzl-FormField-control--l">
          <div className="gzl-Input">
            <Field
              name="billing_address"
              data-testid="billing_address"
              aria-required="true"
              type="text"
              id="billing_address"
            />
          </div>
        </div>
        <ErrorMessage name="billing_address" />
      </div>

      <div className="idt-FormField-sideBySide">
        <div className={`gzl-FormField u-spacingInline2 ${errorClassName("billing_city", errors, touched)}`}>
          <label className="gzl-FormField-label" htmlFor="billing_city">
            City
          </label>
          <div className="gzl-FormField-control gzl-FormField-control--l">
            <div className="gzl-Input">
              <Field name="billing_city" data-testid="gds_city" aria-required="true" type="text" id="billing_city" />
            </div>
          </div>
          <ErrorMessage name="billing_city" />
        </div>
        <div className={`gzl-FormField u-spacingInline2 ${errorClassName("billing_state", errors, touched)}`}>
          <label className="gzl-FormField-label" htmlFor="billing_state">
            State
          </label>
          <div className="gzl-FormField-control">
            <div className="gzl-Select">
              <Field name="billing_state" className="input-State" id="billing_state" as="select">
                <option value=""></option>
                <option value="AL">AL</option>
                <option value="AK">AK</option>
                <option value="AZ">AZ</option>
                <option value="AR">AR</option>
                <option value="CA">CA</option>
                <option value="CO">CO</option>
                <option value="CT">CT</option>
                <option value="DE">DE</option>
                <option value="DC">DC</option>
                <option value="FL">FL</option>
                <option value="GA">GA</option>
                <option value="HI">HI</option>
                <option value="ID">ID</option>
                <option value="IL">IL</option>
                <option value="IN">IN</option>
                <option value="IA">IA</option>
                <option value="KS">KS</option>
                <option value="KY">KY</option>
                <option value="LA">LA</option>
                <option value="ME">ME</option>
                <option value="MD">MD</option>
                <option value="MA">MA</option>
                <option value="MI">MI</option>
                <option value="MN">MN</option>
                <option value="MS">MS</option>
                <option value="MO">MO</option>
                <option value="MT">MT</option>
                <option value="NE">NE</option>
                <option value="NV">NV</option>
                <option value="NH">NH</option>
                <option value="NJ">NJ</option>
                <option value="NM">NM</option>
                <option value="NY">NY</option>
                <option value="NC">NC</option>
                <option value="ND">ND</option>
                <option value="OH">OH</option>
                <option value="OK">OK</option>
                <option value="OR">OR</option>
                <option value="PA">PA</option>
                <option value="RI">RI</option>
                <option value="SC">SC</option>
                <option value="SD">SD</option>
                <option value="TN">TN</option>
                <option value="TX">TX</option>
                <option value="UT">UT</option>
                <option value="VT">VT</option>
                <option value="VA">VA</option>
                <option value="WA">WA</option>
                <option value="WV">WV</option>
                <option value="WI">WI</option>
                <option value="WY">WY</option>
              </Field>
            </div>
          </div>
          <ErrorMessage name="billing_state" />
        </div>
        <div className={`gzl-FormField u-spacingInline2  ${errorClassName("billing_postal_code", errors, touched)}`}>
          <label className="gzl-FormField-label" htmlFor="billing_postal_code">
            ZIP Code
          </label>
          <div className="gzl-FormField-control gzl-FormField-control--xs">
            <div className="gzl-Input">
              <Field
                name="billing_postal_code"
                aria-required="true"
                type="tel"
                pattern="[0-9]{5}"
                maxLength="5"
                id="billing_postal_code"
              />
            </div>
          </div>
          <ErrorMessage name="billing_postal_code" />
        </div>
      </div>
    </>
  )
}
