import "core-js/features/array/find-index"
import "react-app-polyfill/ie11"
import "core-js/features/url-search-params"
import "core-js/features/object/values"
import React from "react"
import ReactDOM from "react-dom"

import { IdtForm } from "./IdtForm"

import "./global.css"

ReactDOM.render(
  <React.StrictMode>
    <IdtForm />
  </React.StrictMode>,
  document.getElementById("root")
)
