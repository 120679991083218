export const formatCardNumber = (incomingValue: string): { formattedValue: string; maxLength: number } => {
  // remove all non digit characters
  const value = incomingValue.replace(/\D/g, "")
  let formattedValue = ""
  let maxLength = 19
  // american express, 15 digits
  if (/^3[47]\d{0,13}$/.test(value)) {
    formattedValue = value.replace(/(\d{4})/, "$1 ").replace(/(\d{4}) (\d{6})/, "$1 $2 ")
    maxLength = 17
  }
  // regular cc number, 16 digits
  else if (/^\d{0,16}$/.test(value)) {
    formattedValue = value
      .replace(/(\d{4})/, "$1 ")
      .replace(/(\d{4}) (\d{4})/, "$1 $2 ")
      .replace(/(\d{4}) (\d{4}) (\d{4})/, "$1 $2 $3 ")
    maxLength = 19
  }

  return { formattedValue, maxLength }
}
