import { FC } from "react"
import { useField } from "formik"

import { IdtFormValues } from "./IdtForm"

export type Props = {
  name: keyof IdtFormValues
}

export const ErrorMessage: FC<Props> = ({ name }) => {
  const meta = useField(name)[1]

  if (meta.error && meta.touched) {
    return <p className="gzl-FormField-error">{meta.error}</p>
  }

  return null
}
