export interface Meta {
  params: string

  userBrowser: string

  url: string

  [key: string]: any
}

export enum OriginId {
  reliashield = "reliashield",
  zander = "zander",
  zander_group_benefits = "zander_group_benefits"
}

export enum FamilyComposition {
  Spouse = "Spouse",
  Dependents = "Dependents",
  SpouseAndDependents = "SpouseAndDependents"
}

export interface Product {
  pricingRoutineNumber?: 4 | 5 | 19 | 21 | 43 | 44 | 59 | 60

  familyPricingRoutineNumber?: number

  subscriptionType?: number

  couponCode?: string
}

export interface Address {
  address: string

  city: string

  state: string

  zip: string
}

export interface BasicPersonalData {
  firstName: string

  lastName: string

  emailAddress: string
}

export interface Insured extends BasicPersonalData {
  initialOfMiddleName?: string

  enrollmentAddress: Address

  billingAddress: Address

  dateOfBirth: string

  phoneNumber: string
}

export interface Spouse extends BasicPersonalData {}

export enum PaymentMethods {
  DebitAndCredit = "CC",
  ElectronicCheck = "EC"
}

export enum CardTypes {
  Visa = "Visa",
  Master = "Master",
  Amex = "Amex",
  Discover = "Discover",
  VISA = "VISA",
  MASTER = "MASTER",
  AMEX = "AMEX",
  DISCOVER = "DISCOVER",
  visa = "visa",
  master = "master",
  amex = "amex",
  discover = "discover"
}

export enum AccountTypes {
  Checking = "Checking",
  Savings = "Savings",
  checking = "checking",
  savings = "savings"
}

export interface PaymentMethod {
  method: PaymentMethods

  cardType?: CardTypes

  cardNumber?: string

  firstNameOnCard?: string

  lastNameOnCard?: string

  expirationMonth?: string

  expirationYear?: string

  securityCode?: string

  accountType?: AccountTypes

  routingNumber?: string

  bankName?: string

  accountNumber?: string

  nameOfAccountHolder?: string
}

export interface IdentityTheftRequestDto {
  prefix?: string

  product: Product

  familyComposition?: FamilyComposition

  spouse?: Spouse

  couponCode?: string

  originId: OriginId

  insured: Insured

  paymentMethod?: PaymentMethod

  anonymousClientId?: string

  meta: Meta
}

export interface PurchaseSinglePlanResponse {
  data: {
    subscriberNumber: string
    request: IdentityTheftRequestDto
    spouseSubscriberNumber?: string
    savedAlAttribution?: boolean
  }
}
