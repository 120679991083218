import { useEffect, useState } from "react"
import { Formik, FormikHelpers, Field, FormikErrors, FormikTouched } from "formik"
import { object, string, boolean } from "yup"
import axios from "axios"

// components
import { BillingAddress } from "./BillingAddress"
import { ErrorMessage } from "./ErrorMessage"
import { Loader } from "./Loader"

// utils
import { formatCardNumber } from "./lib/format_credit_card.util"
import { numbersOnly } from "./lib/numbers_only.util"

// types
import {
  IdentityTheftRequestDto,
  PurchaseSinglePlanResponse,
  PaymentMethods,
  CardTypes,
  AccountTypes
} from "./dto/IdentityTheftRequest.dto"

import "./IdtForm.css"

export type IdtFormValues = {
  billing_frequency: "" | "monthly" | "yearly"
  payment_method: "CC" | "EC"
  card_type: "visa" | "master" | "amex" | "discover"
  card_number: string
  card_first_name: string
  card_last_name: string
  card_expiration_date: string
  cvv: string
  billing_address_different: boolean
  agreed_to_terms: boolean
  billing_address: string
  billing_city: string
  billing_state: string
  billing_postal_code: string
  bank_RTN: string
  eCheck_account_number: string
  bank_name: string
  account_holder_name: string
  account_type: "Checking" | "checking" | "Savings" | "savings"
}

export type EventName =
  | "zander.idt.payment.payment_failure"
  | "zander.idt.payment.payment_success"
  | "zander.idt.payment.api_load_success"
  | "zander.idt.payment.api_load_failure"
  | "zander.idt.payment.missing_lead_id"

export type RamseyPostMessage = {
  name: EventName
  payload?:
    | {}
    | {
        id: string
      }
}

const schema = object().shape({
  billing_frequency: string().required("Please select a billing frequency"),
  payment_method: string().required("Please select a payment method."),
  card_type: string().when("payment_method", {
    is: "CC",
    then: string().required("Please select a card type.")
  }),
  card_number: string().when("payment_method", {
    is: "CC",
    then: string()
      .transform((currentVal, originalVal) => currentVal.replace(/\s+/g, ""))
      .label("Card number")
      .required("Please enter a valid card number")
      .min(13, "Card number must be at least 13 digits")
      .max(16, "Card number must be a maximum of 16 digits")
  }),
  card_first_name: string().when("payment_method", {
    is: "CC",
    then: string().label("First Name").required("Please enter your first name").max(30)
  }),
  card_last_name: string().when("payment_method", {
    is: "CC",
    then: string().required("Please enter your last name").max(30)
  }),
  card_expiration_date: string().when("payment_method", {
    is: "CC",
    then: string()
      .label("expiration date")
      .required("Please enter a valid expiration date")
      .matches(/([0-9]{2})\/([0-9]{2})/, "Please enter a valid expiration date")
  }),
  cvv: string().when("payment_method", {
    is: "CC",
    then: string().label("Security Code").required("Please enter a security code").min(3).max(4)
  }),
  billing_address_different: boolean().required(),
  agreed_to_terms: boolean().oneOf([true], "Please accept the terms and conditions"),
  billing_address: string().when("billing_address_different", {
    is: false,
    then: string().required("Please enter your street address")
  }),
  billing_city: string().when("billing_address_different", {
    is: false,
    then: string().required("Please enter your city")
  }),
  billing_state: string().when("billing_address_different", {
    is: false,
    then: string().required("Please enter your state")
  }),
  billing_postal_code: string()
    .label("ZIP code")
    .when("billing_address_different", {
      is: false,
      then: string().required("Please enter your ZIP code").min(5)
    }),
  bank_RTN: string().when("payment_method", {
    is: "EC",
    then: string()
      .transform((currentVal, originalVal) => currentVal.replace(/\s+/g, ""))
      .transform((value, originalValue) => (/^[0-9]+$/.test(originalValue) ? originalValue : ""))
      .label("Routing Number")
      .required("Please enter a valid card number")
      .min(9, "Routing number must be at least 9 digits")
      .max(9, "Routing number must be a maximum of 9 digits")
      .required("Please enter valid routing number")
  }),
  eCheck_account_number: string().when("payment_method", {
    is: "EC",
    then: string().required("Please enter a valid account number")
  }),
  bank_name: string().when("payment_method", {
    is: "EC",
    then: string().required("Please enter the name of your bank")
  }),
  account_holder_name: string().when("payment_method", {
    is: "EC",
    then: string().required("Please enter account holder name")
  }),
  account_type: string().when("payment_method", {
    is: "EC",
    then: string().required("Please select an account type")
  })
})

export const IdtForm = () => {
  const [leadId] = useState(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const leadId = urlParams.get("lead_id")

    if (leadId) {
      return leadId
    }

    return ""
  })
  const [leadData, setLeadData] = useState<IdentityTheftRequestDto>()
  const [initVals, setInitVals] = useState<IdtFormValues>({
    billing_frequency: "yearly",
    payment_method: "CC",
    card_type: "visa",
    card_number: "",
    card_first_name: "",
    card_last_name: "",
    card_expiration_date: "",
    cvv: "",
    billing_address_different: true,
    agreed_to_terms: false,
    billing_address: "",
    billing_city: "",
    billing_postal_code: "",
    billing_state: "",
    bank_RTN: "",
    eCheck_account_number: "",
    bank_name: "",
    account_holder_name: "",
    account_type: "Checking"
  })
  const [errorMessage, setErrorMessage] = useState("")
  // const [planResponse, setPlanResponse] = useState<PurchaseSinglePlanResponse>()
  const [isSuccessful, setIsSuccessful] = useState(false)
  const [prices, setPrices] = useState<{ yearly: string; monthly: string; savings: string }>({
    yearly: "",
    monthly: "",
    savings: ""
  })
  const [conditionalTC, setConditionalTC] = useState<boolean>(false)

  const sendPostMessage = (data: RamseyPostMessage) => {
    window.parent.postMessage(data, "*")
  }

  useEffect(() => {
    if (!leadId) {
      setErrorMessage("Sorry! We're experiencing some trouble.")
      sendPostMessage({
        name: "zander.idt.payment.missing_lead_id"
      })
    } else {
      const fetchLeadData = async () => {
        try {
          const responseData = await axios.get<IdentityTheftRequestDto>(
            `${process.env.REACT_APP_MAPI_URL}/identity_theft/lead/${leadId}`,
            {
              // @TODO: how long is this timer?
              timeout: 5000
            }
          )

          // check whether it is a yearly or monthly billing plan
          if (
            responseData.data.product.pricingRoutineNumber === 5 ||
            responseData.data.product.pricingRoutineNumber === 21 ||
            responseData.data.product.pricingRoutineNumber === 43 ||
            responseData.data.product.pricingRoutineNumber === 44 ||
            responseData.data.product.pricingRoutineNumber === 59 ||
            responseData.data.product.pricingRoutineNumber === 60
          ) {
            setInitVals({ ...initVals, ...{ billing_frequency: "yearly" } })
          } else {
            setInitVals({ ...initVals, ...{ billing_frequency: "monthly" } })
          }

          // check if this is a family plan
          if (
            responseData.data.product?.pricingRoutineNumber === 19 ||
            responseData.data.product?.pricingRoutineNumber === 21 ||
            responseData.data.product?.pricingRoutineNumber === 44 ||
            responseData.data.product?.pricingRoutineNumber === 60
          ) {
            setPrices({
              yearly: "$145.00",
              monthly: "$12.90",
              savings: "$9.80"
            })
          } else if (
            responseData.data.product?.pricingRoutineNumber === 5 ||
            responseData.data.product?.pricingRoutineNumber === 4 ||
            responseData.data.product?.pricingRoutineNumber === 43 ||
            responseData.data.product?.pricingRoutineNumber === 59
          ) {
            setPrices({
              yearly: "$75.00",
              monthly: "$6.75",
              savings: "$6.00"
            })
          } else {
            setErrorMessage("Sorry! We're experiencing some trouble.")
          }

          setLeadData(responseData.data)
          sendPostMessage({
            name: "zander.idt.payment.api_load_success"
          })

          //set conditional terms and conditions page link in the form for 90 days free offer
          if (
            responseData.data.product.pricingRoutineNumber === 4 ||
            responseData.data.product.pricingRoutineNumber === 59 ||
            responseData.data.product.pricingRoutineNumber === 19 ||
            responseData.data.product.pricingRoutineNumber === 60
          ) {
            setConditionalTC(true)
          }
        } catch (err) {
          console.error(err)

          // @TODO: how to handle these sorts of errors?
          setErrorMessage("Sorry! We're experiencing some trouble.")
          sendPostMessage({
            name: "zander.idt.payment.api_load_failure"
          })
        }
      }

      setTimeout(() => {
        fetchLeadData()
      }, 500)
    }
  }, [])

  const submitHandler = async (values: IdtFormValues, helpers: FormikHelpers<IdtFormValues>) => {
    if (leadData) {
      let isFamilyPlan = false
      let isFree30Days = false
      let isFree90Days = false
      if (
        leadData?.product.pricingRoutineNumber === 19 ||
        leadData?.product.pricingRoutineNumber === 21 ||
        leadData?.product.pricingRoutineNumber === 44 ||
        leadData?.product.pricingRoutineNumber === 60
      ) {
        isFamilyPlan = true
      }

      if (
        leadData?.product.pricingRoutineNumber === 43 ||
        leadData?.product.pricingRoutineNumber === 44 ||
        leadData?.product.couponCode?.toLowerCase() === "free30days"
      ) {
        isFree30Days = true
      }

      if (
        leadData?.product.pricingRoutineNumber === 59 ||
        leadData?.product.pricingRoutineNumber === 60 ||
        leadData?.product.couponCode?.toLowerCase() === "90daysfree"
      ) {
        isFree90Days = true
      }

      // transform request
      const getPrn = () => {
        if (isFamilyPlan) {
          if (values.billing_frequency === "monthly") {
            return 19
          }
          if (values.billing_frequency === "yearly") {
            if (isFree30Days) {
              return 44
            } else if (isFree90Days) {
              return 60
            } else {
              return 21
            }
          }
        } else {
          if (values.billing_frequency === "monthly") {
            return 4
          }
          if (values.billing_frequency === "yearly") {
            if (isFree30Days) {
              return 43
            } else if (isFree90Days) {
              return 59
            } else {
              return 5
            }
          }
        }

        return 4
      }
      const prn = getPrn()

      const getFamilyPrn = () => {
        if (prn === 19) {
          return 20
        }
        if (prn === 21 || prn === 44 || prn === 60) {
          return 22
        }
        return 0
      }
      const familyPrn = getFamilyPrn()

      const getExpDate = () => {
        return values.card_expiration_date.split("/")
      }
      const [expirationMonth, expirationYear] = getExpDate()

      const finalRequestData: IdentityTheftRequestDto = {
        ...leadData,
        ...{
          product: {
            pricingRoutineNumber: prn,
            familyPricingRoutineNumber: familyPrn
          },
          insured: {
            ...leadData.insured,
            ...{
              billingAddress: !values.billing_address_different
                ? {
                    address: values.billing_address,
                    city: values.billing_city,
                    state: values.billing_state,
                    zip: values.billing_postal_code
                  }
                : leadData.insured.enrollmentAddress
            }
          },
          paymentMethod:
            values.payment_method === "CC"
              ? {
                  method: PaymentMethods.DebitAndCredit,
                  cardNumber: values.card_number.replace(/\s+/g, ""),
                  firstNameOnCard: values.card_first_name,
                  lastNameOnCard: values.card_last_name,
                  expirationMonth,
                  expirationYear,
                  securityCode: values.cvv,
                  cardType: CardTypes[values.card_type]
                }
              : {
                  method: PaymentMethods.ElectronicCheck,
                  accountType: AccountTypes[values.account_type],
                  routingNumber: values.bank_RTN,
                  bankName: values.bank_name,
                  accountNumber: values.eCheck_account_number,
                  nameOfAccountHolder: values.account_holder_name
                },
          meta: {
            ...leadData?.meta,
            ...{
              leadId: leadId
            }
          }
        }
      }

      if (isFree30Days && values.billing_frequency === "monthly") {
        finalRequestData.couponCode = "FREE30DAYS"
      }

      if (isFree90Days && values.billing_frequency === "monthly") {
        finalRequestData.couponCode = "90DAYSFREE"
      }

      try {
        console.log("finalRequestData", finalRequestData)
        const submitResponse = await axios.post<PurchaseSinglePlanResponse>(
          `${process.env.REACT_APP_MAPI_URL}/identity_theft/plan`,
          finalRequestData,
          { timeout: 30000 }
        )

        if (typeof submitResponse.data?.data?.subscriberNumber === "string") {
          setIsSuccessful(true)
          sendPostMessage({
            name: "zander.idt.payment.payment_success",
            payload: {
              id: submitResponse.data.data.subscriberNumber
            }
          })
        } else {
          console.error("Idt success response did not return a subscriber number")
          setErrorMessage("Sorry! We're experiencing some trouble. Please check your card information and try again.")
        }
      } catch (err) {
        console.error(err)

        setErrorMessage("Sorry! We're experiencing some trouble. Please check your card information and try again.")
        sendPostMessage({
          name: "zander.idt.payment.payment_failure"
        })
      }
    }
  }

  const errorClassName = (
    keyName: keyof IdtFormValues,
    errors: FormikErrors<IdtFormValues>,
    touched: FormikTouched<IdtFormValues>
  ) => {
    if (errors && typeof errors[keyName] === "string") {
      if (touched && typeof touched[keyName] === "boolean" && touched[keyName] === true) {
        return "is-invalid"
      }
    }

    return ""
  }

  return (
    <div className="idt-Form">
      <Formik
        initialValues={initVals}
        onSubmit={submitHandler}
        validationSchema={schema}
        validateOnMount={false}
        enableReinitialize
      >
        {({ values, isSubmitting, handleSubmit, setFieldValue, errors, touched, validateField }) => {
          if (!leadData && !errorMessage) {
            return <Loader />
          }

          return (
            <>
              {leadData && <h2 className="gzl-Heading gzl-Heading--secondary">Submit Payment</h2>}

              <form onSubmit={handleSubmit}>
                <fieldset className={`gzl-FormField ${errorClassName("billing_frequency", errors, touched)}`}>
                  <legend className="gzl-FormField-label">Billing Frequency</legend>
                  <div className="gzl-Radio">
                    <Field name="billing_frequency" type="radio" id="yearly" value="yearly" />
                    <label htmlFor="yearly">
                      <span>{prices.yearly}</span>
                      <span> yearly </span>
                      <span className="idt-FormField-callout u-typesetBodySmall">
                        SAVE <span>{prices.savings}</span>
                      </span>
                    </label>
                  </div>

                  <div className="gzl-Radio">
                    <Field name="billing_frequency" type="radio" id="monthly" value="monthly" />
                    <label htmlFor="monthly">
                      <span>{prices.monthly}</span>
                      <span> monthly </span>
                    </label>
                  </div>

                  <ErrorMessage name="billing_frequency" />
                </fieldset>
                <fieldset className={`gzl-FormField ${errorClassName("payment_method", errors, touched)}`}>
                  <legend className="gzl-FormField-label">Payment Method</legend>
                  <div className="gzl-Radio">
                    <Field name="payment_method" type="radio" id="CC" value="CC" />
                    <label htmlFor="CC">Credit / Debit Card</label>
                  </div>
                  <div className="gzl-Radio">
                    <Field name="payment_method" type="radio" id="EC" value="EC" />
                    <label htmlFor="EC">Pay by Electronic Check</label>
                  </div>
                </fieldset>
                {values.payment_method === "CC" && (
                  <>
                    <fieldset className={`gzl-FormField ${errorClassName("card_type", errors, touched)}`}>
                      <legend className="gzl-FormField-label">Card Type</legend>
                      <div className="gzl-Radio">
                        <Field name="card_type" type="radio" id="visa" value="visa" />
                        <label htmlFor="visa">Visa</label>
                      </div>
                      <div className="gzl-Radio">
                        <Field name="card_type" type="radio" id="master" value="master" />
                        <label htmlFor="master">MasterCard</label>
                      </div>
                      <div className="gzl-Radio">
                        <Field name="card_type" type="radio" id="amex" value="amex" />
                        <label htmlFor="amex">American Express</label>
                      </div>
                      <div className="gzl-Radio">
                        <Field name="card_type" type="radio" id="discover" value="discover" />
                        <label htmlFor="discover">Discover</label>
                      </div>

                      <ErrorMessage name="card_type" />
                    </fieldset>

                    <div className={`gzl-FormField ${errorClassName("card_number", errors, touched)}`}>
                      <label className="gzl-FormField-label" htmlFor="card_number">
                        Card Number
                      </label>
                      <div className="gzl-FormField-control gzl-FormField-control--m">
                        <div className="gzl-Input">
                          <Field
                            // @ts-ignore
                            onChange={(event) => {
                              const val = event.target.value
                              const formatData = formatCardNumber(val)

                              if (val.length > formatData.maxLength) {
                                return
                              }

                              if (formatData.formattedValue !== values.card_number) {
                                setFieldValue("card_number", formatData.formattedValue, true)
                              } else {
                                setFieldValue("card_number", val, true)
                              }
                            }}
                            name="card_number"
                            id="card_number"
                            type="tel"
                            maxLength={22}
                          />
                        </div>
                      </div>
                      <ErrorMessage name="card_number" />
                    </div>

                    <div className="idt-FormField-sideBySide">
                      <div
                        className={`gzl-FormField u-spacingInline2 ${errorClassName(
                          "card_first_name",
                          errors,
                          touched
                        )}`}
                      >
                        <label className="gzl-FormField-label" htmlFor="card_first_name">
                          First Name
                        </label>
                        <div className="gzl-FormField-control gzl-FormField-control">
                          <div className="gzl-Input">
                            <Field name="card_first_name" id="card_first_name" type="text" />
                          </div>
                        </div>
                        <ErrorMessage name="card_first_name" />
                      </div>
                      <div className={`gzl-FormField ${errorClassName("card_last_name", errors, touched)}`}>
                        <label className="gzl-FormField-label" htmlFor="card_last_name">
                          Last Name
                        </label>
                        <div className="gzl-FormField-control gzl-FormField-control">
                          <div className="gzl-Input">
                            <Field name="card_last_name" id="card_last_name" />
                          </div>
                        </div>
                        <ErrorMessage name="card_last_name" />
                      </div>
                    </div>

                    <div className="idt-FormField-sideBySide">
                      <div
                        className={`gzl-FormField u-spacingInline2 ${errorClassName(
                          "card_expiration_date",
                          errors,
                          touched
                        )}`}
                      >
                        <label className="gzl-FormField-label" htmlFor="card_expiration_date">
                          Expiration Date
                        </label>
                        <div className="gzl-FormField-control gzl-FormField-control--m">
                          <div className="gzl-Input">
                            <Field
                              // @ts-ignore
                              onChange={(event) => {
                                let val: string = event.target.value ? event.target.value : ""

                                if (typeof parseInt(val) !== "number") {
                                  return
                                }

                                if (val.length > 5) {
                                  return
                                }

                                if (val.length > 2 && val.charAt(2) !== "/") {
                                  val = val.slice(0, 2) + "/" + val.slice(2)
                                }

                                setFieldValue("card_expiration_date", val, true)
                              }}
                              name="card_expiration_date"
                              id="card_expiration_date"
                              type="text"
                              inputMode="numeric"
                            />
                          </div>
                        </div>
                        <ErrorMessage name="card_expiration_date" />

                        <p className="gzl-FormField-help">MM/YY</p>
                      </div>
                      <div className={`gzl-FormField ${errorClassName("cvv", errors, touched)}`}>
                        <label className="gzl-FormField-label" htmlFor="cvv">
                          Security Code
                        </label>
                        <div className="gzl-FormField-control gzl-FormField-control--xs">
                          <div className="gzl-Input">
                            <Field name="cvv" id="cvv" maxLength="4" type="text" inputMode="numeric" />
                          </div>
                        </div>
                        <ErrorMessage name="cvv" />
                      </div>
                    </div>

                    <div className="gzl-FormField">
                      <div className="gzl-Checkbox">
                        <Field type="checkbox" name="billing_address_different" id="billing_address_different" />
                        <label htmlFor="billing_address_different">
                          My billing address is the same as my address on the previous step.
                        </label>
                      </div>
                    </div>

                    {!values.billing_address_different && (
                      <BillingAddress errorClassName={errorClassName} errors={errors} touched={touched} />
                    )}
                  </>
                )}
                {values.payment_method === "EC" && (
                  <>
                    <div
                      className={`gzl-FormField ${errorClassName("bank_RTN", errors, touched)}`}
                      style={{ marginTop: "1rem" }}
                    >
                      <label className="gzl-FormField-label" htmlFor="bank_RTN">
                        Routing Number
                      </label>
                      <div className="gzl-FormField-control gzl-FormField-control--m">
                        <div className="gzl-Input">
                          <Field
                            // @ts-ignore
                            onChange={(event) => {
                              const val = event.target.value
                              const formatData = numbersOnly(val)

                              if (formatData.formattedValue !== values.bank_RTN) {
                                setFieldValue("bank_RTN", formatData.formattedValue, true)
                              } else {
                                setFieldValue("bank_RTN", val, true)
                              }
                            }}
                            name="bank_RTN"
                            id="bank_RTN"
                            type="tel"
                            maxLength={9}
                          />
                        </div>
                      </div>
                      <ErrorMessage name="bank_RTN" />
                    </div>
                    <div className={`gzl-FormField ${errorClassName("eCheck_account_number", errors, touched)}`}>
                      <label className="gzl-FormField-label" htmlFor="eCheck_account_number">
                        Account Number
                      </label>
                      <div className="gzl-FormField-control gzl-FormField-control--m">
                        <div className="gzl-Input">
                          <Field
                            // @ts-ignore
                            onChange={(event) => {
                              const val = event.target.value
                              const formatData = numbersOnly(val)

                              if (formatData.formattedValue !== values.eCheck_account_number) {
                                setFieldValue("eCheck_account_number", formatData.formattedValue, true)
                              } else {
                                setFieldValue("eCheck_account_number", val, true)
                              }
                            }}
                            name="eCheck_account_number"
                            id="eCheck_account_number"
                            type="tel"
                            maxLength={100}
                          />
                        </div>
                      </div>
                      <ErrorMessage name="eCheck_account_number" />
                    </div>
                    <div className={`gzl-FormField ${errorClassName("bank_name", errors, touched)}`}>
                      <label className="gzl-FormField-label" htmlFor="bank_name">
                        Bank Name
                      </label>
                      <div className="gzl-FormField-control gzl-FormField-control--m">
                        <div className="gzl-Input">
                          <Field
                            // @ts-ignore
                            name="bank_name"
                            id="bank_name"
                            type="text"
                          />
                        </div>
                      </div>
                      <ErrorMessage name="bank_name" />
                    </div>

                    <div className={`gzl-FormField ${errorClassName("account_holder_name", errors, touched)}`}>
                      <label className="gzl-FormField-label" htmlFor="account_holder_name">
                        Account Holder Name
                      </label>
                      <div className="gzl-FormField-control gzl-FormField-control--m">
                        <div className="gzl-Input">
                          <Field
                            // @ts-ignore
                            name="account_holder_name"
                            id="account_holder_name"
                            type="text"
                          />
                        </div>
                      </div>
                      <ErrorMessage name="account_holder_name" />
                    </div>
                    <legend className="gzl-FormField-label" style={{ marginTop: "1.5rem" }}>
                      Account Type
                    </legend>
                    <div className="gzl-Radio">
                      <Field name="account_type" type="radio" id="Checking" value="Checking" />
                      <label htmlFor="Checking">Checking</label>
                    </div>
                    <div className="gzl-Radio" style={{ marginBottom: "1.5rem" }}>
                      <Field name="account_type" type="radio" id="Savings" value="Savings" />
                      <label htmlFor="Savings">Savings</label>
                    </div>
                  </>
                )}

                <div className={`gzl-FormField gzl-Checkbox ${errorClassName("agreed_to_terms", errors, touched)}`}>
                  <Field name="agreed_to_terms" id="agreed_to_terms" type="checkbox" />
                  <label htmlFor="agreed_to_terms">
                    I agree to the Zander Identity Theft Solutions {/* @TODO: ask for where to link */}
                    {conditionalTC ? (
                      <a
                        target="_blank"
                        href="https://www.zanderins.com/identity-theft-protection/terms-and-conditions-3-months-free-offer"
                        rel="noreferrer"
                      >
                        Terms and Conditions - 3 Months Free Offer
                      </a>
                    ) : (
                      <a
                        target="_blank"
                        href="https://www.zanderins.com/identity-theft-protection/terms-and-conditions"
                        rel="noreferrer"
                      >
                        Terms and Conditions
                      </a>
                    )}{" "}
                    and I consent to the{" "}
                    <a target="_blank" href="https://www.zanderins.com/privacy-policy" rel="noreferrer">
                      Privacy Policy
                    </a>
                    .
                  </label>
                  <ErrorMessage name="agreed_to_terms" />
                </div>

                <div className="u-spacingStack2">
                  <button
                    type="submit"
                    className="gzl-Button gzl-Button--comfortable"
                    disabled={isSubmitting || isSuccessful}
                  >
                    {isSubmitting ? "Loading..." : "Submit Payment"}
                  </button>
                </div>

                {errorMessage && <p className="gzl-FormField-error">{errorMessage}</p>}

                <p className="gzl-FormField-help">Your payment will be processed by Zander. You can cancel anytime.</p>
              </form>
            </>
          )
        }}
      </Formik>
    </div>
  )
}
