import "./loader.css"

export const Loader = () => {
  return (
    <div>
      <div className="frontman-LoadingPlaceholder frontman-LoadingPlaceholder--xs u-spacingStack4"></div>
      <div className="frontman-LoadingPlaceholder frontman-LoadingPlaceholder--xl"></div>
      <div className="frontman-LoadingPlaceholder frontman-LoadingPlaceholder--s u-spacingStack6"></div>
      <div className="frontman-LoadingPlaceholder frontman-LoadingPlaceholder--m u-spacingStack4"></div>
      <div className="u-spacingStack4"></div>
      <div className="frontman-LoadingPlaceholder frontman-LoadingPlaceholder--l u-spacingStack4"></div>
      <div className="frontman-SkeletonLayout-buttonContainer">
        <div className="frontman-LoadingPlaceholder frontman-LoadingPlaceholder--xs"></div>
        <div className="frontman-LoadingPlaceholder frontman-LoadingPlaceholder--xs"></div>
      </div>
    </div>
  )
}
